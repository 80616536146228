import React, { useState } from 'react'
import moment from 'moment'
import classnames from 'classnames'

import { weekdays } from '@zupr/utils/datetime'
import { openToday } from '@zupr/utils/hours'

import Trans from '../../components/trans'
import SkeletonLoading from '../../components/skeleton-loading'

import '../../../../scss/react/components/hours-table.scss'

const HoursForDay = ({ day, hours }) => {
    // day of the week to date
    const m = moment().day(day)
    const dayOfWeek = weekdays[m.isoWeekday() % 7]
    const date = m.format('YYYY-MM-DD')

    const hoursDay =
        (hours.exceptions && hours.exceptions[date]) || hours[dayOfWeek]

    if (hoursDay && hoursDay.length) {
        return (
            <React.Fragment>
                {hoursDay.map((slot) => (
                    <React.Fragment key={`${day}-${slot.join(' - ')}`}>
                        {slot.join(' - ')}
                        <br />
                    </React.Fragment>
                ))}
            </React.Fragment>
        )
    }

    return <Trans label="Closed" context="opening-hours" />
}

const OpeningHours = ({ summary, hours, loading }) => {
    const days = moment.weekdays(true)

    const [open, setOpen] = useState(false)

    const isToday = (day) => moment().diff(moment().day(day), 'days') === 0

    const handleOpen = () => setOpen(true)

    if (!hours || !Object.keys(hours).length) return null

    if (summary && !open) {
        const today = openToday(hours)

        return (
            <p className="more-hours">
                {((today.now || today.later) && (
                    <span>
                        <Trans
                            label="Open today from"
                            context="opening-hours"
                        />{' '}
                        <br />
                        <strong className="hours-today">
                            {today.hours.map((slot, i) => (
                                <React.Fragment key={`slot-${i}`}>
                                    {slot.join(' - ')}
                                    <br />
                                </React.Fragment>
                            ))}
                        </strong>
                    </span>
                )) ||
                    (today.hours && (
                        <span>
                            <Trans
                                label="Closed for the day"
                                context="opening-hours"
                            />
                        </span>
                    )) || (
                        <span>
                            <Trans label="Closed" context="opening-hours" />
                        </span>
                    )}
                <button className="inline" onClick={handleOpen}>
                    <Trans
                        label="Show more opening hours"
                        context="opening-hours"
                    />
                </button>
            </p>
        )
    }

    if (loading) {
        return (
            <ul className="hours-table">
                {days.map((day) => (
                    <li
                        key={day}
                        className={classnames({
                            current: isToday(day),
                        })}
                    >
                        <div>
                            {day.replace(/^\w/, (chr) => chr.toUpperCase())}
                        </div>
                        <div>
                            <SkeletonLoading lineHeight={16} />
                        </div>
                    </li>
                ))}
            </ul>
        )
    }

    return (
        <div className="hours-table">
            {days.map((day) => (
                <React.Fragment key={day}>
                    <div
                        className={classnames({
                            current: isToday(day),
                        })}
                    >
                        {day.replace(/^\w/, (chr) => chr.toUpperCase())}
                    </div>
                    <div
                        className={classnames({
                            current: isToday(day),
                        })}
                    >
                        <HoursForDay day={day} hours={hours} />
                    </div>
                </React.Fragment>
            ))}
        </div>
    )
}

OpeningHours.defaultProps = {
    summary: false,
    loading: false,
    hours: null,
}

export default OpeningHours
