import { format, parseISO, roundToNearestMinutes } from 'date-fns'
import { nl } from 'date-fns/locale'

export type Weekday =
    | 'sunday'
    | 'monday'
    | 'tuesday'
    | 'wednesday'
    | 'thursday'
    | 'friday'
    | 'saturday'

export const weekdays: Weekday[] = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
]

export const weekdaysLocalized: Weekday[] = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
]

export const dayString = () => {
    const d = new Date()
    return weekdays[d.getDay()]
}

export const dayToLocalDay = (day: Weekday) => {
    const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1)

    // make a new date with date-fns and set the day to a specific day
    const date = new Date()

    const currentDayIndex = date.getDay()
    const dayIndex = weekdays.indexOf(day)
    var diffIndex = dayIndex - currentDayIndex
    date.setDate(date.getDate() + diffIndex)

    return capitalize(
        format(date, 'EEEE', {
            locale: nl,
        })
    )
}

export const daysOfWeekStartingToday = () => {
    const d = new Date().getDay()
    const beforeToday = weekdays.slice(0, d)
    const todayAndAfter = weekdays.slice(d, 7)
    return [...todayAndAfter, ...beforeToday]
}

export const daysOfWeekStartingTomorrow = () => {
    const days = daysOfWeekStartingToday()
    days.push(days.shift()) // push first to end
    return days
}

export const formatDay = (date: string | Date) => {
    const parsedDate = typeof date === 'string' ? parseISO(date) : date
    return format(parsedDate, 'd MMMM', {
        locale: nl,
    })
}

export const formatDayLong = (date: string | Date) => {
    const parsedDate = typeof date === 'string' ? parseISO(date) : date
    return format(parsedDate, 'EEEE d MMMM', {
        locale: nl,
    })
}

export const formatDateTime = (date: string | Date) => {
    const parsedDate = typeof date === 'string' ? parseISO(date) : date
    return format(parsedDate, 'd MMMM yyy, HH:mm', {
        locale: nl,
    })
}

export const roundToNearest = (interval: number, date?: Date) => {
    return roundToNearestMinutes(date || new Date(), {
        nearestTo: interval,
    })
}
