import { Location } from '@zupr/types/fo'
import React, { useContext } from 'react'

import { ReactComponent as MailIcon } from '../../../../svg/email-outlined.svg'
import RouteContext from '../../../context/route'
import Tooltip from '../../components/tooltip'
import ExternalLink from '../../router/link-external'

import '../../../../scss/react/components/action-links.scss'

interface EmailLinkProps {
    location: Location
    className?: string
    onClick?: () => void
    children?: React.ReactNode
    iconOnly?: boolean
    noIcon?: boolean
}

const EmailLink = ({
    location,
    children,
    className,
    iconOnly,
    noIcon,
    onClick,
}: EmailLinkProps) => {
    const { trackEvent } = useContext(RouteContext)

    const handleClick = () => {
        trackEvent({
            id: location.id,
            action: 'clickMail',
        })

        if (onClick) onClick()
    }

    return (
        <ExternalLink
            className={className}
            href={`mailto:${location.email}`}
            onClick={handleClick}
            aria-label="Mail"
        >
            {iconOnly && !noIcon && <MailIcon />}
            {!iconOnly && (
                <>
                    {!noIcon && (
                        <span className="btn-social social-platform">
                            <MailIcon />
                        </span>
                    )}
                    {children || <span>{location.email}</span>}
                </>
            )}
        </ExternalLink>
    )
}

export const EmailAction = ({ ...props }: EmailLinkProps) => (
    <Tooltip label="Mail">
        <EmailLink className="btn btn-small" iconOnly {...props} />
    </Tooltip>
)

export default EmailLink
