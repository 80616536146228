import { Location } from '@zupr/types/fo'
import React, { useContext } from 'react'

import { ReactComponent as PhoneIcon } from '../../../../svg/phone-outlined.svg'
import RouteContext from '../../../context/route'
import Tooltip from '../../components/tooltip'
import ExternalLink from '../../router/link-external'

import '../../../../scss/react/components/action-links.scss'

interface PhoneLinkProps {
    location: Location
    className?: string
    onClick?: () => void
    children?: React.ReactNode
    iconOnly?: boolean
    noIcon?: boolean
}

const PhoneLink = ({
    location,
    className,
    children,
    iconOnly,
    noIcon,
    onClick,
}: PhoneLinkProps) => {
    const { trackEvent } = useContext(RouteContext)

    const handleClick = () => {
        trackEvent({
            id: location.id,
            action: 'clickCall',
        })

        if (onClick) onClick()
    }

    if (!location.phone) return null

    return (
        <ExternalLink
            className={className}
            href={`tel:${location.phone}`}
            onClick={handleClick}
            aria-label="Bel"
        >
            {iconOnly && !noIcon && <PhoneIcon />}
            {!iconOnly && (
                <>
                    {!noIcon && (
                        <span className="btn-social social-platform">
                            <PhoneIcon />
                        </span>
                    )}
                    {children || <span>{location.phone}</span>}
                </>
            )}
        </ExternalLink>
    )
}

export const PhoneAction = ({ ...props }: PhoneLinkProps) => (
    <Tooltip label="Bel">
        <PhoneLink className="btn btn-small" iconOnly {...props} />
    </Tooltip>
)

export default PhoneLink
